import { Link, graphql } from "gatsby";
import * as React from "react";
// import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

// @remember cause gatsby-node.js is node require moduel,
// this also needs to be a require module
const slug = require(`../utils/slug`);

const ArchivePage = ({ data, location }) => {
  //  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMdx.nodes;

  return (
    <Layout location={location} title="Blog">
      <Seo title="Blog" />

      <main className="l-typeArea">
        <h1 className="pb-20 xl6Light">Blog</h1>
        <ol>
          {posts.map((post) => {
            const title = post.frontmatter.title || post.fields.slug;

            return (
              <li key={post.fields.slug}>
                <article
                  className="pb-8"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <h2 className="lgSerifSemibold">
                    <Link
                      to={slug.getPathFromBlogFolderName(post.fields.slug)}
                      itemProp="url"
                    >
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <div>Published {post.frontmatter.date}</div>
                </article>
              </li>
            );
          })}
        </ol>
      </main>
    </Layout>
  );
};

export default ArchivePage;

export const pageQuery = graphql`
  query ArchivPosts {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
